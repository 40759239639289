@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Zen+Dots&display=swap");

@font-face {
    font-family: "GELLIX";
    src: url(../public/fonts/Gellix_Medium.woff2);
}


.font-alt1 {
	font-family: "Zen Dots", cursive;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-ms-user-select: none;
}
.non-selezionabile {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-ms-user-select: none;
}
* {
	font-family: "Montserrat", sans-serif;
	scrollbar-width: none !important;
	border: 0px solid red;
}

::-webkit-scrollbar {
	width: 0.1px;
	opacity: 0%;
}

.position-sticky {
	overflow: clip;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
}
/*
body {
  background-image: url('./assets/esagoni.png');
  background-color: #cccccc;
}
*/
.lavori_btn{
  opacity: 100%;
}
.lavori_btn:hover > .lavori_img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
}
.lavori_img:hover {
  filter: blur(0px);
  -webkit-filter: blur(0px);
}
@media only screen and (min-width: 640px) {
  .lavori_btn{
    opacity: 0;
  }
	.lavori:hover > .lavori_txt {
		opacity: 100%;
	}
	.lavori:hover > .lavori_btn {
		opacity: 1;
	}
	.lavori_btn:hover > .lavori_img {
		filter: blur(4px);
		-webkit-filter: blur(4px);
	}
	.lavori_img:hover {
		filter: blur(4px);
		-webkit-filter: blur(4px);
	}
}

.arrow-down {
	width: 60px;
	height: 40px;
	margin: 0 0 0 -30px;
	position: absolute;
	left: 50%;
	bottom: 0px;
	-webkit-animation: arrow 0.5s 1s infinite ease-out alternate;
}

.arrow-down:hover {
	-webkit-animation-play-state: paused;
}

.left {
	position: absolute;
	height: 10px;
	width: 40px;
	background: black;
	-webkit-transform: rotate(240deg);
	top: 10px;
	left: 10px;
	-webkit-border-radius: 4px;
	-webkit-transform-origin: 5px 50%;
	-webkit-animation: leftArrow 0.5s 1s infinite ease-out alternate;
}

.right {
	position: absolute;
	height: 10px;
	width: 40px;
	background: black;
	-webkit-transform: rotate(-60deg);
	top: 10px;
	left: 10px;
	-webkit-border-radius: 4px;
	-webkit-transform-origin: 5px 50%;
	-webkit-animation: rightArrow 0.5s 1s infinite ease-out alternate;
}

@keyframes arrow {
	0% {
		bottom: 0px;
	}
	100% {
		bottom: 40px;
	}
}

@keyframes leftArrow {
	0% {
	}
	100% {
		-webkit-transform: rotate(225deg);
	}
}

@keyframes rightArrow {
	0% {
	}
	100% {
		-webkit-transform: rotate(-45deg);
	}
}
